<template>
  <div class="bg-white">
    <div class="fixed top-0 left-0 hidden h-full w-1/2 bg-white lg:block" aria-hidden="true"/>
    <div class="fixed top-0 right-0 hidden h-full w-1/2 bg-primary lg:block" aria-hidden="true"/>

    <header :class="{'border-b border-sky-700': sideShown && hasCollapseInformation}"
            class="relative mx-auto max-w-7xl bg-primary py-6 lg:grid lg:grid-cols-2 lg:gap-x-16 lg:bg-transparent lg:px-8 lg:pt-16 lg:pb-10 lg:border-none">
      <div class="mx-auto flex max-w-2xl px-4 lg:w-full lg:max-w-lg lg:px-0 items-center justify-between">
        <img src="@/assets/logo_white.svg" alt="" class="h-8 w-auto lg:hidden"/>
        <img src="@/assets/logo.svg" alt="" class="hidden h-12 w-auto lg:block"/>

        <slot name="collapse" :toggle="toggleCollapse" :open="mobileSummaryShown"/>
      </div>
    </header>

    <main class="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8">
      <TransitionExpand>
        <section aria-labelledby="summary-heading" v-show="sideShown"
                 class="relative bg-primary text-sky-200 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent">
          <div class="pb-12 md:px-10 lg:px-0 lg:pb-24">
            <slot name="side"/>
          </div>
        </section>
      </TransitionExpand>

      <section
        class="pt-6 pb-4 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pt-0 lg:min-h-[calc(100vh-152px)] lg:flex lg:flex-col">
        <div class="flex-grow">
          <slot/>
        </div>

        <BaseContainer class="w-full flex-grow-0">
          <div
            class="mt-10 pt-4 border-t border-gray-200 text-xs text-gray-400 flex flex-col md:flex-row items-center flex-wrap gap-4">
            <div class="flex items-center space-center gap-4">
              <a href="https://hb-marketplace.com/pages/agb" target="_blank" class="block hover:underline">AGB</a>
              <a href="https://hb-marketplace.com/pages/nutzungsbedingungen" target="_blank"
                 class="block hover:underline">Nutzungsbedingungen</a>
            </div>
            <div class="flex items-center space-center gap-4">
              <a href="https://hb-marketplace.com/pages/datenschutz" target="_blank" class="block hover:underline">Datenschutz</a>
              <a href="https://hb-marketplace.com/pages/impressum" target="_blank" class="block hover:underline">Impressum</a>
            </div>
          </div>
        </BaseContainer>
      </section>
    </main>

    <TheOfflineIndicator/>
  </div>
</template>

<style>
.collapse-enter-active,
.collapse-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
}
</style>

<script lang="ts" setup>
import {breakpointsTailwind} from "@vueuse/core";
import {computed, useBreakpoints, useSlots} from "#imports";

const props = defineProps<{
  total?: string;
}>()

const mobileSummaryShown = ref<boolean>(false);
const breakpoints = useBreakpoints(breakpointsTailwind)
const onDesktop = breakpoints.greaterOrEqual('lg');
const slots = useSlots();

const hasCollapseInformation = computed(() => !!slots['collapse'])
const toggleCollapse = () => mobileSummaryShown.value = !mobileSummaryShown.value
const sideShown = computed(
  () => !hasCollapseInformation.value || onDesktop.value || mobileSummaryShown.value
)

onDesktop.value = true;
onMounted(() => onDesktop.value = breakpoints.isGreaterOrEqual('lg'));
</script>
