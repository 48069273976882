<script lang="ts" setup>
const enter = (element: HTMLElement) => {
  element.style.width = getComputedStyle(element).width;
  element.style.position = 'absolute';
  element.style.visibility = 'hidden';
  element.style.height = 'auto';

  const height = getComputedStyle(element).height;

  element.style.width = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style.height = 0;

  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = height;
  });
}

const afterEnter = (element: HTMLElement) => {
  element.style.height = 'auto';
}

const leave = (element: HTMLElement) => {
  element.style.height = getComputedStyle(element).height;
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = 0;
  });

}
</script>

<style>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>

<template>
  <transition name="expand"
              @enter="enter"
              @after-enter="afterEnter"
              @leave="leave">
    <slot/>
  </transition>
</template>
